/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-06-30 08:59:12
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-03 14:14:38
 */
import axios from 'axios'

// import {
//   Message
// } from 'element-ui'

// import store from '@/store'

// import router from '../router'

// const IS_PROD = ['production', 'prod'].includes(process.env.NODE_ENV)

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (response.status == 200) {
      return res
    } else {

      // Message({
      //   message: res.msg || '请求出错',
      //   type: 'error',
      //   duration: 2 * 1000
      // })
      
      return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: '请求出错',
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service