/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-19 10:38:09
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-18 08:52:04
 */
const title = '51公需网数据驾驶舱'

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}