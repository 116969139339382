/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-04-02 20:46:48
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-12 22:04:16
 */
/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
  var timer;
  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return '';
  else {
    const date = new Date(time);
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      );
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ?
          o[k] :
          ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
    return fmt;
  }
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export const parseTimeTwo = (s) => {
  if (!s) return '--'

  var day = Math.floor(s / (24 * 3600)); // Math.floor()向下取整 
  var hour = Math.floor((s - day * 24 * 3600) / 3600);
  var minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60);
  var second = Math.floor(s - day * 24 * 3600 - hour * 3600 - minute * 60);
  if (s > 86400) {
    //天
    return day + "d" + hour + "h" + minute + "m" + second + "s"
  } else if (s > 3600) {
    //小时
    return hour + "h" + minute + "m" + second + "s"
  } else if (s > 60) {
    //分钟
    return minute + "m" + second + "s"
  } else {
    return second + "s"
  }
}


export const getRoudom = (min, max, number) => {
  //获取随机数 
  let arr = []
  for (let i = 0; i < number; i++) {
    arr.push(min + Math.round(Math.random() * (max - min)))
  }
  return arr
}

export function randomString(len = 10) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
  const maxPos = chars.length
  let str = ''
  for (let i = 0; i < len; i++) {
    str += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return str
}

export async function blockInit({
  url,
  method,
  el,
  data
}) {
  try {
    //总能耗时序分析
    let res = await this.$unionReq({
      url,
      method: method || 'GET',
      data
    })

    return res

  } catch (error) {
    console.log(`接口:${url}报错`);
  } finally {
    if (el) {
      setTimeout(() => {
        el.loading = false
      }, 500);
    }
  }
}

export const xAixs = (numer = 24) => {
  return {
    name: 'h',
    data: (() => {
      let key = false
      let arr = []
      for (let i = 1; i <= numer; i++) {
        arr.push(`${ key ? i : ''}`)
        key = !key
      }
      return arr
    })(),
    axisLabel: {
      interval: 0,
      rotate: 0,
    }
  }
}

export const getStatus = (val) => {
  switch (val) {
    case 1:
      return {
        name: '正常',
          color: 'text-success'
      };
    case 2:
      return {
        name: '异常',
          color: 'color'
      };
    case -1:
      return {
        name: '停机',
          color: 'text-danger'
      };
    case 0:
      return {
        name: '故障',
          color: 'text-info'
      };
    default:
      return {
        name: '故障',
          color: 'text-info'
      };
  }
}