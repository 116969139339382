/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-06-30 09:01:19
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-01 14:07:06
 */
import request from '@/utils/request'

export function unionReq({
    url,
    method,
    params,
    data,
    responseType
}) {
    //获取字典接口
    return request({
        url, //
        method,
        params,
        data,
        responseType
    })
}