/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-04-02 20:46:46
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-12 22:04:33
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import './permission';

// import './theme/index.css'

// import './styles/element-variables.scss'

import {
  unionReq
} from '@/api'

import {
  blockInit,
  xAixs,
  getStatus
} from '@/utils'

//引入echart
//4.x 引用方式
import echarts from 'echarts'

//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$unionReq = unionReq
Vue.prototype.$xAixs = xAixs
Vue.prototype.$getStatus = getStatus



Vue.prototype.$blockInit = blockInit

Vue.config.productionTip = false;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)


// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');