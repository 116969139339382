/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-04-02 20:46:48
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-25 17:49:49
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

import optimizedDecision from './modules/optimizedDecision'

Vue.use(VueRouter)

const routes = [optimizedDecision.routers]

const router = new VueRouter({
  routes
})

export default router