/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-01 14:11:39
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-29 13:23:07
 */
const routers = {
    path: '/',
    name: 'optimizedDecision',
    component: () => import('@/layout/optimizedDecision/index.vue'),
    redirect: 'screen_1',
    meta: {
        title: '优化决策系统',
        auth: true
    },
    children: [{
        path: '/screen_1',
        name: 'screen_1',
        meta: {
            title: '数据大屏1',
            auth: true
        },
        component: () => import('@/views/optimizedDecision/screen_1/index.vue')
    }, {
        path: '/screen_2',
        name: 'screen_2',
        meta: {
            title: '数据大屏2',
            auth: true
        },
        component: () => import('@/views/optimizedDecision/screen_2/index.vue')
    }]
}

export default {
    routers
}