/*
 * @Author: your name
 * @Date: 2020-06-10 21:55:20
 * @LastEditTime: 2022-08-25 17:50:03
 * @LastEditors: guozong
 * @Description: In User Settings Edit
 * @FilePath: /BHErp/src/permission.js
 */
import router from './router'
// import store from './store'


import NProgress from 'nprogress' // progress bar

import 'nprogress/nprogress.css' // progress bar style

import getPageTitle from '@/utils/get-page-title'

// NProgress.configure({
//   showSpinner: false
// })

router.beforeEach(async (to, from, next) => {
  // 读取进度条
  NProgress.start()

  // 修改标题
  document.title = getPageTitle(to.meta.title)

  // 确定用户是否已经登录

  next()

})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})